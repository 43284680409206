import { AlpineComponent } from "alpinejs";
import { setupSdk } from "@matterport/sdk";

interface State extends Record<string | symbol, unknown> {}

export default (key: unknown, space: unknown): AlpineComponent<State> => ({
  async init() {
    if (typeof key !== "string" || typeof space !== "string") {
      return;
    }

    await setupSdk(key, {
      container: this.$el,
      space,
    });
  },
});
